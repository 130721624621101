import { Icons } from '../icons'
import {
  Menu,
  MenuButton,
  MenuList,
  IconButton,
  Box,
  Flex,
  Image,
  Avatar,
  Text,
  Icon,
  Skeleton,
  SkeletonCircle,
  Show,
} from '@chakra-ui/react'
import { HiMiniSignal } from 'react-icons/hi2'
import { IoMdAlarm } from 'react-icons/io'
import { IoDocumentOutline } from 'react-icons/io5'
import { useRouter } from 'next/navigation'
import { DraftVideoCardMenu, VideoCardMenu } from './video-card-menu'
import { UploadVideoCardMenu } from './video-card-menu'
import PremiumTag from '../video/PremiumTag'
import { DraftData } from './my-channel/my-channel-drafts'
import { useUser } from '@/src/store/user-store'
import { getInitials } from './avatar'
import { intervalToDuration } from 'date-fns'
import { useRef, useState } from 'react'
import { VideoOnHover } from './video-hover-play'

interface VideoCardProps {
  eventId?: string
  thumbnailUrl?: string
  videoDuration: string
  avatarUrl?: string
  creatorName: string
  creatorId?: string
  title: string
  timelineUpload?: string
  live?: string
  watchTime: string
  scheduled?: React.ReactNode
  draft?: boolean
  uploadStatus?: string
  creator?: boolean
  omitDetails?: boolean
  isWatchList?: string
  encryptVideoUrl?: string
  premium?: boolean
  price?: string
  themeColor?: string
  channelShareName?: string
  isVerifiedTick?: string
  fileToken?: string
  uploadedVideo?: boolean
  onOpen?: (
    fileToken: string,
    transcoding: string,
    draftData: DraftData
  ) => void
  draftData?: DraftData
  watchCount?: string
  sharedImageName?: string
  hlsLink?: string
  inProgress?: boolean
}
export const VideoCard: React.FC<VideoCardProps> = ({
  eventId,
  thumbnailUrl,
  videoDuration,
  avatarUrl,
  creatorName,
  creatorId,
  title,
  timelineUpload,
  watchTime,
  live,
  scheduled,
  draft,
  uploadStatus,
  creator,
  omitDetails,
  isWatchList,
  encryptVideoUrl,
  premium,
  price,
  themeColor,
  channelShareName,
  isVerifiedTick,
  fileToken,
  uploadedVideo,
  onOpen,
  draftData,
  watchCount,
  sharedImageName,
  hlsLink,
  inProgress
}) => {
  const videoData = {
    thumbnailUrl,
    videoDuration,
    watchTime,
    title,
    creatorName,
    eventId,
    encryptVideoUrl,
    themeColor,
    sharedImageName,
  }
  const router = useRouter()
  let MenuComponent: React.ReactNode = null
  if (uploadedVideo) {
    MenuComponent = (
      <UploadVideoCardMenu
        eventId={eventId}
        isWatchList={isWatchList}
        creatorId={creatorId}
        videoData={videoData}
      />
    )
  } else if (draft || scheduled || inProgress) {
    MenuComponent = <DraftVideoCardMenu fileToken={fileToken} scheduled={scheduled} inProgress={inProgress?eventId:""} />
  } else {
    MenuComponent = (
      <VideoCardMenu
        eventId={eventId}
        isWatchList={isWatchList}
        creatorId={creatorId}
        videoData={videoData}
      />
    )
  }

  function handleClick() {
    if (encryptVideoUrl) {
      router.push(encryptVideoUrl)
    } else if (
      (draft || scheduled) &&
      uploadStatus != 'failed' &&
      onOpen &&
      fileToken &&
      draftData
    ) {
      let transcoding = '0'
      if (uploadStatus == 'Waiting to publish') {
        transcoding = '1'
      }
      onOpen(fileToken, transcoding, draftData)
    }
  }
  function convertToDaysHoursMinutes(start: Date | string, end: Date | string): string {
    // Convert inputs to Date objects
    const startDate = new Date(start);
    const endDate = new Date(end);

    // Validate dates
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      throw new Error('Invalid date input. Please provide valid Date objects or date strings.');
    }

    // Calculate the duration between the two dates
    const duration = intervalToDuration({
      start: startDate,
      end: endDate,
    });

    // Format the result as a string
    if (duration?.minutes && !(duration?.minutes < 0)) {
      return `${duration.days || 0} days : ${duration.hours || 0} hours : ${duration.minutes} minutes`;
    } else {
      return `Publising in progress`;
    }

  }

  return (
    <Flex
      w="100%"
      direction="column"
      textColor="lyk-black"
      cursor={'pointer'}
      scrollSnapAlign={'start'}
      rowGap={2}
    >
      <Box
        position="relative"
        w="full"
        h="auto"
        aspectRatio={16 / 9}
        rounded="xl"
        overflow="hidden"
        bg="gray.300"
        onClick={handleClick}
      >
        <VideoOnHover
          key={eventId}
          hlsLink={hlsLink || ""}
          thumbnailUrl={inProgress&&!thumbnailUrl?"/video/blackThumbnail.jpg":thumbnailUrl ? thumbnailUrl : ""}
          title={title}
          live={live}
          premium={premium}
          videoDuration={videoDuration}
          encryptVideoUrl={encryptVideoUrl}
        />
        {draft && (
          <Flex
            position="absolute"
            bottom="10px"
            left="10px"
            alignItems="center"
            gap={2}
          >
           {!inProgress&& <Flex
              alignItems="center"
              h="22px"
              rounded="md"
              bg="#00000070"
              color="white"
              fontSize="xs"
              fontWeight="semibold"
              overflow="hidden"
            >
              <Box
                h="100%"
                w="auto"
                aspectRatio={1}
                bg="lyk-red"
                display="grid"
                placeItems="center"
              >
                <Icon as={IoDocumentOutline} h="14px" w="14px" color="white" />
              </Box>
              <Flex h="100%" px={2.5} alignItems="center">
                <Text>Draft</Text>
              </Flex>
            </Flex>}

            {uploadStatus && (
              <Flex
                alignItems="center"
                h="22px"
                rounded="md"
                bg={
                  uploadStatus == 'Upload cancelled' ? 'lyk-red' : '#00000070'
                }
                color="white"
                fontSize="xs"
                fontWeight="semibold"
                overflow="hidden"
                px={2.5}
              >
                {uploadStatus}
              </Flex>
            )}
          </Flex>
        )}

        {price && (
          <Flex
            position="absolute"
            bottom="12px"
            left="12px"
            bg="#00000070"
            color="white"
            fontSize="xs"
            fontWeight="semibold"
            px={2.5}
            py={1.2}
            rounded="md"
            alignItems="center"
            gap={1.5}
          >
            {parseFloat(price).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </Flex>
        )}

        {scheduled && (
          <Flex
            position="absolute"
            bottom="12px"
            left="12px"
            bg="#00000070"
            color="white"
            fontSize="xs"
            fontWeight="semibold"
            px={2.5}
            py={1.2}
            rounded="md"
            alignItems="center"
            gap={1.5}
          >
            <Icon as={IoMdAlarm} h={4} w={4} />
            {convertToDaysHoursMinutes(new Date(), new Date(draftData?.scheduleDateTime || ""))}
          </Flex>
        )}

        {live ? (
          <Flex
            position="absolute"
            bottom="10px"
            right="10px"
            bg="lyk-red"
            color="white"
            fontSize="sm"
            fontWeight="semibold"
            px={2}
            py={1.2}
            rounded="md"
            alignItems="center"
            gap={1}
          >
            <Flex transform={'rotate(30deg)'} alignItems="center">
              <Icon as={HiMiniSignal} h={4} w={4} />
            </Flex>
            <span>LIVE</span>
          </Flex>
        ) : <></>}
      </Box>

      {!omitDetails ? (
        <Flex
          alignItems="flex-start"
          justifyContent="space-between"
          w="100%"
          onClick={handleClick}
        >
          <Box>
            <Avatar
              name={creatorName}
              src={formatUrl(avatarUrl || '')}
              getInitials={getInitials}
              h="40px"
              w="40px"
              bg={themeColor}
              color="white"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                router.push('/c/' + channelShareName)
              }}
            />
          </Box>

          <Box flex="1" mx={2}>
            <Text
              fontSize="base"
              fontWeight="500"
              noOfLines={2}
              wordBreak="break-word"
              onClick={handleClick}
              aria-label={title}
            >
              {title}
            </Text>
            <Flex
              alignItems="center"
              columnGap={1.5}
              mt={1}
              wrap="wrap"
              gap={1}
            >
              <Text
                fontSize="small"
                fontWeight="400"
                color="lyk-green"
                backgroundColor={'#24BFA31F'}
                rounded={'xl'}
                px={1.5}
                py={0.5}
                marginRight={1}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation()
                  router.push('/c/' + channelShareName)
                }}
              >
                {creatorName}
                <Image
                  src="/my-channel/verified-tag.svg"
                  alt="verified-tag"
                  display={isVerifiedTick == '1' ? 'inline' : 'none'}
                  ml={isVerifiedTick == '1' ? 1 : 0}
                  h="auto"
                  mb={0.5}
                  w={[5, 5, 5]}
                />
              </Text>

              <Flex
                alignItems="center"
                color="gray.500"
                columnGap={1}
                marginRight={1}
                backgroundColor={'#ECECEC'}
                rounded={'xl'}
                px={1.5}
                py={0.5}
              >
                {live ? (
                  <Text fontSize="small" color="inherit">
                    {live} watching
                  </Text>
                ) : (
                  <Text fontSize="small" color="inherit">
                    {formatTimeUpload(timelineUpload || '')}
                  </Text>
                )}
              </Flex>

              {watchCount ? (
                <Flex
                  alignItems="center"
                  color="gray.500"
                  columnGap={1}
                  marginRight={1}
                  backgroundColor={'#ECECEC'}
                  rounded={'xl'}
                  px={1.5}
                  py={0.5}
                >
                  <Icons.eye className="color-[inherit] h-3.5 w-3.5" />
                  <Text fontSize="small" color="inherit">
                    {watchCount} {Number(watchCount) === 1 ? 'view' : 'views'}
                  </Text>
                </Flex>
              ) : (
                ''
              )}
              <Flex
                alignItems="center"
                color="lyk-green"
                backgroundColor={'#24BFA31F'}
                rounded={'xl'}
                columnGap={1}
                px={1.5}
                py={0.5}
              >
                <Icons.clock className="color-[inherit] h-3 w-3" />
                <Text fontSize="small" color="inherit">
                  {formatWatchTime(watchTime)} watched
                </Text>
              </Flex>
            </Flex>
          </Box>

          <Menu closeOnBlur>
            <MenuButton
              minW={'auto'}
              as={IconButton}
              aria-label="Options"
              icon={<Icons.VerticalDot className=" h-6 w-6 text-[#9f9f9f]" />}
              variant="ghost"
              px={0}
              _hover={{ bg: 'transparent' }}
              _active={{ bg: 'transparent' }}
              h={6}
              pt={1}
              onClick={(e: React.MouseEvent) => e.stopPropagation()}
            />
            <MenuList
              onClick={(e: React.MouseEvent) => e.stopPropagation()}
              minW={180}
              textColor="lyk-black"
              py={1}
              fontSize={{
                base: 'small',
                sm: 'medium',
              }}
              zIndex={999999}
              fontWeight={500}
              bg="white"
            >
              {MenuComponent}
              {/* {draft ? (
                uploadStatus !== 'Upload cancelled' ? (
                  <>
                    <MenuItem
                      icon={
                        <Icon
                          as={FaRegCircleXmark}
                          color="#3e3e3e"
                          h={4}
                          w={4}
                        />
                      }
                      opacity={0.75}
                      _hover={{ bg: '#ECECEC' }}
                    >
                      Cancel Upload
                    </MenuItem>
                  </>
                ) : (
                  <></>
                )
              ) : (
                <>
                  <VideoCardMenu
                    eventId={eventId}
                    isWatchList={isWatchList}
                    creatorId={creatorId}
                    videoData={videoData}
                  />
                </>
              )}  */}
              {/* {creator && (
                <MenuItem
                  icon={<Icon as={FaRegTrashCan} color="red.500" h={4} w={4} />}
                  opacity={0.75}
                  _hover={{ bg: '#ECECEC' }}
                  color="red.500"
                  onClick={() => {
                    console.log(`deleting vid ${fileToken}`)
                  }}
                >
                  Delete video
                </MenuItem>
              )} */}
            </MenuList>
          </Menu>
        </Flex>
      ) : (
        <Text
          fontSize="base"
          fontWeight="500"
          noOfLines={2}
          wordBreak="break-word"
        >
          {title}
        </Text>
      )}
    </Flex>
  )
}

export const LoadingWideVideoCard: React.FC = () => {
  return (
    <>
      <Show above="md">
        <Flex
          className="w-full"
          textColor="lyk-black"
          cursor={'pointer'}
          scrollSnapAlign={'start'}
          gap={2}
        >
          <Skeleton
            rounded="xl"
            w={'60%'}
            maxW={'350px'}
            aspectRatio={16 / 9}
            overflow="hidden"
            bg="gray.300"
          ></Skeleton>

          <Flex
            alignItems="flex-start"
            justifyContent="space-evenly"
            w="100%"
            direction={'column'}
            gap={'2'}
            p="4"
          >
            <Skeleton height="15px" w="80%" />
            <Box
              className="flex-start flex w-full flex-row items-center"
              mt="2"
            >
              <SkeletonCircle size="40px" />
              <Skeleton height="12px" w="20%" mx="6" />
            </Box>
            <Skeleton height="12px" w="60%" mt="1" />
          </Flex>
        </Flex>
      </Show>
      <Show below="md">
        <LoadingVideoCard />
      </Show>
    </>
  )
}

export const LoadingVideoCard = ({ id }: { id?: number }) => {
  return (
    <Flex
      w="100%"
      direction="column"
      textColor="lyk-black"
      cursor={'pointer'}
      scrollSnapAlign={'start'}
      rowGap={2}
    >
      {
        id !== undefined ? <Image
          position="relative"
          aspectRatio={16 / 9}
          src={`blurPreview/${id < 10 ? id + 1 : Math.floor(Math.random() * 10) + 1}_blur.jpg`}
          h="auto"
          w="full"
          alt={"preview thumbnail"}
          aria-label={"preview thumbnail"}
          rounded={'xl'}
        /> :
          <Skeleton rounded="xl">
            <Box
              position="relative"
              w="full"
              h="auto"
              aspectRatio={16 / 9}
              rounded="xl"
              overflow="hidden"
              bg="gray.300"
            ></Box>
          </Skeleton>
      }



      <Flex alignItems="flex-start" justifyContent="space-between" w="100%">
        <SkeletonCircle size="40px" />

        <Box flex="1" mx={2} mt={1}>
          <Skeleton height="15px" w="80%" />
          <Skeleton height="12px" w="60%" mt={2} />
        </Box>
      </Flex>
    </Flex>
  )
}
export const formatWatchTime = (watchTime: string) => {
  if (Number(watchTime) < 3600) {
    return 'Under 1 hr'
  }

  return `Over ${Math.floor(Number(watchTime) / 3600)} hr`
}

export const formatTimeUpload = (date: string) => {
  const uploadDate = new Date(date.replace(/\s/, 'T'))
  uploadDate.setMinutes(
    uploadDate.getMinutes() - uploadDate.getTimezoneOffset()
  )
  const currentDate = new Date()
  const timeDifference = currentDate.getTime() - uploadDate.getTime()
  const seconds = timeDifference / 1000
  const minutes = seconds / 60
  const hours = minutes / 60
  const days = hours / 24
  const weeks = days / 7
  const months = weeks / 4
  const years = months / 12

  if (seconds < 60) {
    return `Just now`
  }

  if (minutes < 60) {
    if (Math.floor(minutes) == 1) {
      return `${Math.floor(minutes)} Min Ago`
    } else {
      return `${Math.floor(minutes)} Mins Ago`
    }
  }

  if (hours < 24) {
    if (Math.floor(hours) == 1) {
      return `${Math.floor(hours)} Hr Ago`
    } else {
      return `${Math.floor(hours)} Hrs Ago`
    }
  }

  if (days < 7) {
    if (Math.floor(days) == 1) {
      return `${Math.floor(days)} Day Ago`
    } else {
      return `${Math.floor(days)} Days Ago`
    }
  }

  if (weeks < 4) {
    if (weeks < 2) {
      return `${Math.floor(weeks)} Wk Ago`
    }
    return `${Math.floor(weeks)} Wks Ago`
  }

  if (months < 12) {
    if (months < 2) {
      return `${Math.floor(months)} Mo Ago`
    }
    return `${Math.floor(months)} Mos Ago`
  }

  if (months == 12) {
    return `${Math.floor(years)} Yr Ago`
  }

  return `${Math.floor(years)} Yrs Ago`
}

const formatUrl = (url: string) => {
  if (url && !url.startsWith('http')) {
    return `https://lykstage.com/upload/uploads/${url}`
  } else return url
}
