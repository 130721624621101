import {
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  RadioGroup,
  Radio,
  Stack,
  Link,
} from '@chakra-ui/react'
import React from 'react'

export default function CopyrightVideo({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'md'} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius={20} ml={'15px'} mr={'15px'}>
        <ModalHeader pb={7} pt={7} color={'#3d3d3db3'} fontWeight={500} fontSize={'1.5rem'}>Copyright claim</ModalHeader>
        <ModalCloseButton size={'19px'} color={'#3d3d3db3'} mt={7} mr={'1.5rem'} />
        <hr />
        <>
          <ModalBody mt={5} mb={1}>
            If you own the copyright for this video, please email us at <Link
          color="lyk-green"
          sx={{
            textDecoration: 'underline',
            textDecorationColor: 'lyk-green',
          }}
          href="mailto:ip@lykstage.com"
        >ip@lykstage.com</Link> with proof of ownership so we can review and address your complaint.
          </ModalBody>
          <ModalBody mb={5}>
            Someone from our team will get back to you shortly!
          </ModalBody>
        </>
      </ModalContent>
    </Modal>
  )
}
