import { makeApiRequest } from '@/src/lib/api-request'
import { useMutation } from '@tanstack/react-query'
import { useQueryClient } from '@tanstack/react-query'

interface ChannelData {
  eventId: string
}

export const useDeleteVideoMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['user', 'update'],
    mutationFn: async (data: ChannelData) => {
      await makeApiRequest({
        url: '/Channel/deleteChannelEvent',
        method: 'POST',
        data: data,
        isAuthenticated: true,
        fallbackError: 'Failed to update delete video',
        isV3Api:true
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['uploaded-videos'] })
      queryClient.invalidateQueries({ queryKey: ['in-progress-videos'] })
      queryClient.invalidateQueries({ queryKey: ['all-video-count'] })
    },
  })
}
