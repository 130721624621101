import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  Image,
  Link,
  Button,
  // Checkbox,
  // Input,
} from '@chakra-ui/react'
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  EmailShareButton,
  LinkedinShareButton,
} from 'react-share'
import { Icons } from '@/components/icons'
import { toast } from 'sonner'
import { VideoCard } from '../ui/video-card'

interface videoDataInterface {
  thumbnailUrl?: string
  videoDuration: string
  watchTime: string
  title: string
  creatorName: string
  eventId?: string
  themeColor?: string
  encryptVideoUrl?: string
  sharedImageName?: string
}

export default function ShareVideo({
  isOpen,
  onClose,
  link,
  videoData,
}: {
  isOpen: boolean
  onClose: () => void
  link: string
  videoData: videoDataInterface
}) {
  const copyToClipboardLink = () => {
    navigator.clipboard.writeText(
      'https://lykstage.com' + videoData.encryptVideoUrl
    )
    toast.success('Copied to clipboard')
  }

  const copyToClipboardEmbed = () => {
    navigator.clipboard.writeText(
      `<iframe width="560" height="315" src="${'https://lykstage.com' + videoData.encryptVideoUrl}" title="LykStage video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`
    )
    toast.success('Copied to clipboard')
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'sm'} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Share this video</ModalHeader>
        <ModalCloseButton mt={2} />
        <hr />

        <>
          <ModalBody mb={5} mt={2}>
            <VideoCard
              thumbnailUrl={videoData.sharedImageName || videoData.thumbnailUrl}
              omitDetails={true}
              videoDuration={videoData.videoDuration}
              themeColor={videoData.themeColor || '#E79051'}
              creatorName={videoData.creatorName}
              title={videoData.title}
              watchTime={videoData.watchTime}
              channelShareName={''}
            />

            <Flex
              mt={3}
              bg={'gray.100'}
              p={3}
              justifyContent={'space-between'}
              alignItems={'center'}
              rounded={'md'}
            >
              <Box>
                <Text fontWeight={500} fontSize={15}>
                  Video link
                </Text>
                <Link
                  fontWeight={500}
                  fontSize={15}
                  color={'#318FD2'}
                  href={'https://lykstage.com' + videoData.encryptVideoUrl}
                  target="_blank"
                >
                  {'lykstage.com' + videoData.encryptVideoUrl}
                </Link>
              </Box>
              <Box className="cursor-pointer" onClick={copyToClipboardLink}>
                <Image boxSize={6} src={'/copy.svg'} alt="Copy" />
              </Box>
            </Flex>

            <Flex
              mt={3}
              bg={'gray.100'}
              p={3}
              justifyContent={'flex-start'}
              gap={4}
              alignItems={'center'}
              rounded={'md'}
            >
              <Text fontSize={15} fontWeight={500}>
                Share a link
              </Text>
              <Button
                className="cursor-pointer border-none bg-transparent"
                p={'0'}
                size={'xs'}
                onClick={copyToClipboardEmbed}
              >
                <Icons.code className="h-[20px] w-[20px] text-black/70" />
              </Button>
              <FacebookShareButton url={link + videoData.encryptVideoUrl}>
                <Image
                  className="cursor-pointer"
                  boxSize={5}
                  src={'/socials/facebook.svg'}
                  alt="Copy"
                />
              </FacebookShareButton>
              <WhatsappShareButton url={link + videoData.encryptVideoUrl}>
                <Image
                  className="cursor-pointer"
                  boxSize={5}
                  src={'/socials/whatsapp.svg'}
                  alt="Copy"
                />
              </WhatsappShareButton>
              <TwitterShareButton url={link + videoData.encryptVideoUrl}>
                <Image boxSize={5} src={'/socials/twitter.svg'} alt="Copy" />
              </TwitterShareButton>
              <EmailShareButton url={link + videoData.encryptVideoUrl}>
                <Image boxSize={5} src={'/socials/email.svg'} alt="Copy" />
              </EmailShareButton>
              <LinkedinShareButton url={link + videoData.encryptVideoUrl}>
                <Image boxSize={5} src={'/socials/linkedin.svg'} alt="Copy" />
              </LinkedinShareButton>
            </Flex>

            {/* <Flex alignItems={'center'} mt={3} gap={1}>
              <Checkbox fontWeight={500}>Start video from</Checkbox>

              <Input
                type="text"
                variant={'unstyled'}
                color={'lyk-green'}
                border={'none'}
                height={5}
                textDecor={'underline'}
                fontWeight={700}
                flex={1}
                value={'1:00'}
              />
            </Flex> */}
          </ModalBody>
        </>
      </ModalContent>
    </Modal>
  )
}
