import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalOverlay,
  ModalFooter,
  Flex,
  Text,
  Button,
  Image
} from '@chakra-ui/react'

interface Props {
  isOpen: boolean
  onClose: () => void
  handleProceed: (value: 'profile' | 'banner') => void
  text: 'profile' | 'banner'
}

interface DeleteProps {
  isOpen: boolean
  onClose: () => void
  handleProceed: () => void
  text: string
}

export function WarningModal({ isOpen, onClose, handleProceed, text }: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'sm'} isCentered>
      <ModalOverlay />
      <ModalContent zIndex={10000}>
        <ModalHeader>Delete {text} image</ModalHeader>
        <ModalCloseButton
          mt={2}
          className="focus:outline-none focus-visible:outline-none focus-visible:ring-0"
        />
        <hr />
        <ModalBody mb={5} mt={2}>
          <Text fontSize={15}>
            Are you sure you want to delete {text} image?
          </Text>
        </ModalBody>
        <hr />
        <ModalFooter>
          <Flex gap={4}>
            <Button
              px={10}
              type="submit"
              form="change-password-form"
              _hover={{ opacity: 0.7 }}
              size={['sm', 'sm', 'md']}
              fontWeight={500}
              variant={'destructive'}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              px={10}
              type="submit"
              form="change-password-form"
              _hover={{ opacity: 0.7 }}
              size={['sm', 'sm', 'md']}
              fontWeight={500}
              bg={'lyk-red'}
              color={'white'}
              onClick={() => {
                handleProceed(text)
              }}
            >
              Proceed
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export function WarningDeleteModal({
  isOpen,
  onClose,
  handleProceed,
  text,
}: DeleteProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'md'} isCentered>
      <ModalOverlay />
      <ModalContent zIndex={10000} rounded={'20px'}>
        <ModalHeader color={'#3e3e3e'}>Delete {text}</ModalHeader>
        <ModalCloseButton
          mt={2}
          className="focus:outline-none focus-visible:outline-none focus-visible:ring-0"
        />
        <hr />
        <ModalBody mb={1} mt={2} textAlign={'center'}>
          <Image src={'/my-channel/delete.svg'} alt="delete" boxSize={20}  m={'0 auto'}/>
          <Text fontSize={20} fontWeight={500} mt={2} color={'#797979'}>
          Delete {text} permanently ?
          </Text>
        </ModalBody>
 
        <ModalFooter textAlign={'center'} display={'block'} mb={2}>
 
            <Button
              px={10}
              type="submit"
              form="change-password-form"
              _hover={{ opacity: 0.7 }}
              size={['sm', 'sm', 'md']}
              fontWeight={500}
              variant={'destructive'}
              onClick={onClose}
              mr={2}
              color={'lyk-red'}
            >
              Cancel
            </Button>
            <Button
              px={10}
              type="submit"
              form="change-password-form"
              _hover={{ opacity: 0.7 }}
              size={['sm', 'sm', 'md']}
              fontWeight={500}
              bg={'lyk-red'}
              color={'white'}
              onClick={handleProceed}
            >
              Proceed
            </Button>
      
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
