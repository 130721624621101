import { makeApiRequest } from '@/src/lib/api-request'
import { useQuery } from '@tanstack/react-query'

export interface Language {
  id: string
  language: string
  isDelete: string
}

export const useLanguagesQuery = () => {
  return useQuery({
    queryKey: ['languages'],
    queryFn: async () => {
      const response = await makeApiRequest({
        method: 'GET',
        url: '/MasterData/getLanguage',
        isAuthenticated: false,
        fallbackError: 'Error while fetching languages',
        data: {},
      })

      const data: Language[] = response.response
      return data as Language[]
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })
}
