import {
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  RadioGroup,
  Radio,
  Stack,
} from '@chakra-ui/react'
import React from 'react'

export default function ReportVideo({
  isOpen,
  onClose,
  setValue,
  reportVideo,
}: {
  isOpen: boolean
  onClose: () => void
  setValue: (value: string) => void
  reportVideo: (e: React.MouseEvent<HTMLButtonElement>) => void
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'sm'} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Report Video</ModalHeader>
        <ModalCloseButton mt={2} />
        <hr />
        <>
          <ModalBody my={5}>
            <RadioGroup onChange={setValue}>
              <Stack gap={3} direction="column">
                <Radio value="Sexual content">Sexual content</Radio>
                <Radio value="Bullying or Harassment">
                  Bullying or Harassment
                </Radio>
                <Radio value="Hate/Abuse-filled content">
                  Hate/Abuse-filled content
                </Radio>
                <Radio value="Harmful content">Harmful content</Radio>
                <Radio value="Violent or repulsive content">
                  Violent or repulsive content
                </Radio>
                <Radio value="Misinformation">Misinformation</Radio>
                <Radio value="Spam or Misleading">Spam or Misleading</Radio>
                <Radio value="Promotes Terrorism">Promotes Terrorism</Radio>
              </Stack>
            </RadioGroup>
          </ModalBody>
          <hr />

          <ModalFooter>
            <Flex gap={4}>
              <Button
                px={10}
                size={['sm', 'sm', 'md']}
                fontWeight={500}
                onClick={onClose}
                bg={'transparent'}
                color={'#222'}
                variant={'ghost'}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                px={10}
                _hover={{ opacity: 0.7 }}
                size={['sm', 'sm', 'md']}
                fontWeight={500}
                bg={'lyk-green'}
                color={'white'}
                onClick={reportVideo}
              >
                Proceed
              </Button>
            </Flex>
          </ModalFooter>
        </>
      </ModalContent>
    </Modal>
  )
}
