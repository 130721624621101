import { makeApiRequest } from '@/src/lib/api-request'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface addWatchlistMutationParams {
  eventId?: string
}

export const useAddWatchlistMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['user', 'add-watchlist'],
    mutationFn: async (data: addWatchlistMutationParams) => {
      await makeApiRequest({
        url: '/User/addWatchLater',
        method: 'POST',
        data: { eventId: data.eventId },
        isAuthenticated: true,
        fallbackError: 'Failed to add to user watch list',
        isV3Api:true
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['watchlist'],
      })
    },
  })
}

export const useDeleteWatchlistMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['user', 'remove-watchlist'],
    mutationFn: async (data: addWatchlistMutationParams) => {
      await makeApiRequest({
        url: '/User/deleteWatchList',
        method: 'POST',
        data: { eventId: data.eventId },
        isAuthenticated: true,
        fallbackError: 'Failed to remove from user watch list',
        isV3Api:true
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['watchlist'],
      })
    },
  })
}
