import { makeApiRequest } from '@/src/lib/api-request'
import { useMutation } from '@tanstack/react-query'
import { useQueryClient } from '@tanstack/react-query'

interface ChannelData {
  fileToken: string
}

export const useDeleteScheduledMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['user', 'update'],
    mutationFn: async (data: ChannelData) => {
      await makeApiRequest({
        url: '/Video_Upload/deleteSchedule',
        method: 'POST',
        data: data,
        isAuthenticated: true,
        fallbackError: 'Failed to delete scheduled video',
        isV3Api: true
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['scheduled-videos'] })
      queryClient.invalidateQueries({ queryKey: ['all-video-count'] })
    },
  })
}
