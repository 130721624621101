import { Icons } from '../icons'
import {
  MenuItem,
  useDisclosure,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Link,
  Box,
} from '@chakra-ui/react'
import { toast } from 'sonner'
import ReportVideo from '@/components/home/report-video'
import ShareVideo from '@/components/home/share-video'
import { FaRegTrashCan } from 'react-icons/fa6'

import {
  useAddWatchlistMutation,
  useDeleteWatchlistMutation,
} from '@/src/mutations/home/watchlist'
import { useUser } from '@/src/store/user-store'
import { useRouter } from 'next/navigation'
import { useReportVideoMutation } from '@/src/mutations/home/report-video'
import { useDeleteVideoMutation } from '@/src/mutations/my-channel/delete-video'
import { useDeleteDraftMutation } from '@/src/mutations/my-channel/delete-draft'
import { useEffect, useState } from 'react'
import { BookmarkX } from 'lucide-react'
import { WarningDeleteModal } from './my-channel/warning-modal'
import { useDeleteScheduledMutation } from '@/src/mutations/my-channel/delete-scheduled'
import CopyrightVideo from '../home/copyright-video'

export interface VideoData {
  thumbnailUrl?: string
  videoDuration: string
  watchTime: string
  title: string
  creatorName: string
  eventId?: string
  encryptVideoUrl?: string
  themeColor?: string
  channelShareName?: string
  isVerifiedTick?: string
  sharedImageName?: string
}

export const VideoCardMenu = ({
  eventId,
  creatorId,
  isWatchList,
  videoData,
}: {
  eventId?: string
  creatorId?: string
  isWatchList?: string
  videoData: VideoData
}) => {
  const {
    isOpen: isReportOpen,
    onOpen: onReportOpen,
    onClose: onReportClose,
  } = useDisclosure()
  const {
    isOpen: isCopyrightOpen,
    onOpen: onCopyrightOpen,
    onClose: onCopyrightClose,
  } = useDisclosure()
  const {
    isOpen: isShareOpen,
    onOpen: onShareOpen,
    onClose: onShareClose,
  } = useDisclosure()

  const [value, setValue] = useState('')
  const addWatchlistMutation = useAddWatchlistMutation()
  const deleteWatchlistMutation = useDeleteWatchlistMutation()
  const reportVideoMutation = useReportVideoMutation()
  const { getUser } = useUser()
  const user = getUser()
  const router = useRouter()

  const [watchlistState, setWatchListState] = useState(isWatchList ?? '0')

  useEffect(() => {
    setWatchListState(isWatchList ?? '0')
  }, [isWatchList])

  const addToWatchlist = () => {
    if (user) {
      addWatchlistMutation.mutate(
        {
          eventId,
        },
        {
          onSuccess: () => {
            setWatchListState('1')
            toast.success('Added to watchlist', {
              duration: 5000,
              action: {
                label: 'View Watchlist',
                onClick: () => {
                  router.push('/watchlist')
                },
              },
            })
          },
          onError: () => {
            toast.error('Try again!')
          },
        }
      )
    } else {
      router.push('/auth/login')
    }
  }

  const removeFromWatchlist = () => {
    if (user) {
      deleteWatchlistMutation.mutate(
        {
          eventId,
        },
        {
          onSuccess: () => {
            setWatchListState('0')
            toast.success('Removed from watchlist')
          },
          onError: () => {
            toast.error('Try again!')
          },
        }
      )
    } else {
      router.push('/auth/login')
    }
  }

  const reportVideo = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // console.log(value)
    reportVideoMutation.mutate(
      {
        eventId,
        creatorId,
        description: value,
      },
      {
        onSuccess: () => {
          toast.success('Reported successfully')
          window.location.reload()
        },
        onError: () => {
          toast.error('Try again!')
        },
      }
    )

    onReportClose()
  }

  return (
    <>
      <ReportVideo
        isOpen={isReportOpen}
        onClose={onReportClose}
        setValue={setValue}
        reportVideo={reportVideo}
      />
      <ShareVideo
        link="https://lykstage.com"
        isOpen={isShareOpen}
        onClose={onShareClose}
        videoData={videoData}
      />

      <MenuItem
        icon={<Icons.share color="#3E3E3E" size={15} />}
        opacity={0.75}
        _hover={{ bg: '#ECECEC' }}
        onClick={onShareOpen}
      >
        Share
      </MenuItem>
      <MenuItem
        icon={
          watchlistState == '1' ? (
            <BookmarkX color="#ED837F" size={18} />
          ) : (
            <Icons.bookmark color="#3E3E3E" size={15} />
          )
        }
        opacity={0.75}
        _hover={{ bg: '#ECECEC' }}
        onClick={watchlistState == '1' ? removeFromWatchlist : addToWatchlist}
      >
        Watchlist
      </MenuItem>
      <MenuItem
        icon={<Icons.report color="#3E3E3E" size={15} />}
        opacity={0.75}
        _hover={{ bg: '#ECECEC' }}
        onClick={user ? onReportOpen : () => router.push('/auth/login')}
      >
        Report Video
      </MenuItem>
      {/* <MenuItem
        icon={<Icons.report color="#3E3E3E" size={15} />}
        opacity={0.75}
        _hover={{ bg: '#ECECEC' }}
        onClick={user ? onCopyrightOpen : () => router.push('/auth/login')}
      >
        Copyright Claim
      </MenuItem> */}
    </>
  )
}

function DeleteVideo() {
  return (
    <Box className="mb-2 flex flex-1 flex-col items-center justify-center text-center">
      <svg
        width="82"
        height="82"
        viewBox="0 0 82 82"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          opacity="0.24"
          cx="40.6418"
          cy="40.6418"
          r="40.6418"
          fill="#ED837F"
        />
        <circle
          opacity="0.31"
          cx="40.358"
          cy="40.3561"
          r="29.0299"
          fill="#ED837F"
        />
        <path
          d="M35.668 39.6667V34.3333C35.668 31.3878 38.0558 29 41.0013 29C43.9468 29 46.3346 31.3878 46.3346 34.3333V39.6667M31.668 39.6667H50.3346V53H31.668V39.6667Z"
          stroke="#ED837F"
          stroke-width="2"
          stroke-linejoin="round"
        />
      </svg>

      <Text fontSize="xl" fontWeight="bold" my="2">
        Deleting videos is unavailable for your account
      </Text>
      <Text fontSize="md" fontWeight="semibold" my="3" color="#2A2A2AB2">
        This is because you have an account with a special provision.
      </Text>
      <Text fontWeight="semibold" fontSize="md" color="#2A2A2AB2">
        For any further queries, please email us at
        <Link
          color="lyk-green"
          sx={{
            textDecoration: 'underline',
            textDecorationColor: 'lyk-green',
          }}
          href="mailto:support@lykstage.com"
        >
          support@lykstage.com
        </Link>
        .
      </Text>
    </Box>
  )
}

export const UploadVideoCardMenu = ({
  eventId,
  creatorId,
  isWatchList,
  videoData,
}: {
  eventId?: string
  creatorId?: string
  isWatchList?: string
  videoData: VideoData
}) => {
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure()

  const {
    isOpen: isShareOpen,
    onOpen: onShareOpen,
    onClose: onShareClose,
  } = useDisclosure()

  const [value, setValue] = useState('')
  const addWatchlistMutation = useAddWatchlistMutation()
  const deleteWatchlistMutation = useDeleteWatchlistMutation()
  const deleteVideoMutation = useDeleteVideoMutation()
  const { getUser } = useUser()
  const user = getUser()
  const router = useRouter()

  const [videoDeleteModalOpen, setVideoDeleteModalOpen] = useState(false)
  const handleVideoDeleteModalClose = () => setVideoDeleteModalOpen(false)
  const [watchlistState, setWatchListState] = useState(isWatchList ?? '0')

  useEffect(() => {
    setWatchListState(isWatchList ?? '0')
  }, [isWatchList])

  const addToWatchlist = () => {
    if (user) {
      addWatchlistMutation.mutate(
        {
          eventId,
        },
        {
          onSuccess: () => {
            setWatchListState('1')
            toast.success('Added to watchlist', {
              duration: 5000,
              action: {
                label: 'View Watchlist',
                onClick: () => {
                  router.push('/watchlist')
                },
              },
            })
          },
          onError: () => {
            toast.error('Try again!')
          },
        }
      )
    } else {
      router.push('/auth/login')
    }
  }

  const removeFromWatchlist = () => {
    if (user) {
      deleteWatchlistMutation.mutate(
        {
          eventId,
        },
        {
          onSuccess: () => {
            setWatchListState('0')
            toast.success('Removed from watchlist')
          },
          onError: () => {
            toast.error('Try again!')
          },
        }
      )
    } else {
      router.push('/auth/login')
    }
  }

  const deleteVideo = () => {
    deleteVideoMutation.mutate(
      {
        eventId: eventId || '',
      },
      {
        onSuccess: () => {
          toast.success('Deleted successfully')
        },
        onError: () => {
          // toast.error('Try again!')
          // if ('Failed to update delete video') {
          //   setVideoDeleteModalOpen(true)
          // }
          toast.error('Failed to delete video, please try again.')
          setVideoDeleteModalOpen(true)
        },
      }
    )

    onDeleteClose()
  }

  return (
    <>
      <Modal
        isOpen={videoDeleteModalOpen}
        onClose={handleVideoDeleteModalClose}
        size={{ base: 'full', md: 'md' }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <svg
            width="50"
            height="50"
            viewBox="0 0 59 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleVideoDeleteModalClose}
            className="self-end"
          >
            <g opacity="0.5">
              <path
                d="M39.3347 19.666L19.668 39.3327M19.668 19.666L39.3348 39.3327"
                stroke="#3E3E3E"
                stroke-width="3.6875"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
          <ModalBody
            sx={{
              paddingLeft: '20px',
              paddingRight: '20px',
              paddingBottom: '20px',
            }}
          >
            {DeleteVideo()}
          </ModalBody>
        </ModalContent>
      </Modal>

      <WarningDeleteModal
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        handleProceed={deleteVideo}
        text={'video'}
      />
      <ShareVideo
        link="https://lykstage.com"
        isOpen={isShareOpen}
        onClose={onShareClose}
        videoData={videoData}
      />

      <MenuItem
        icon={<Icons.share color="#3E3E3E" size={15} />}
        opacity={0.75}
        _hover={{ bg: '#ECECEC' }}
        onClick={onShareOpen}
      >
        Share
      </MenuItem>
      <MenuItem
        icon={
          watchlistState == '1' ? (
            <BookmarkX color="#ED837F" size={18} />
          ) : (
            <Icons.bookmark color="#3E3E3E" size={15} />
          )
        }
        opacity={0.75}
        _hover={{ bg: '#ECECEC' }}
        onClick={watchlistState == '1' ? removeFromWatchlist : addToWatchlist}
      >
        Watchlist
      </MenuItem>
      <MenuItem
        icon={<Icon as={FaRegTrashCan} color="red.500" h={4} w={4} />}
        opacity={0.75}
        _hover={{ bg: '#ECECEC' }}
        color="red.500"
        onClick={user ? onDeleteOpen : () => router.push('/auth/login')}
      >
        Delete video
      </MenuItem>
    </>
  )
}

export const DraftVideoCardMenu = ({ fileToken,scheduled,inProgress }: { fileToken?: string, scheduled?: React.ReactNode,inProgress?:string }) => {
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure()

  const deleteVideoMutation = useDeleteDraftMutation()
  const deleteScheduledMutation = useDeleteScheduledMutation()
  const deleteInProgressMutation = useDeleteVideoMutation()

  const deleteVideo = () => {
    // console.log(value)
    if(inProgress){
      deleteInProgressMutation.mutate(
        {
          eventId: inProgress || '',
        },
        {
          onSuccess: () => {
            toast.success('Deleted successfully')
          },
          onError: () => {
            toast.error('Try again!')
          },
        }
      )
    }else
    if(scheduled){
      deleteScheduledMutation.mutate(
        {
          fileToken: fileToken || '',
        },
        {
          onSuccess: () => {
            toast.success('Deleted successfully')
          },
          onError: () => {
            toast.error('Try again!')
          },
        }
      )
    }else{
      deleteVideoMutation.mutate(
        {
          fileToken: fileToken || '',
        },
        {
          onSuccess: () => {
            toast.success('Deleted successfully')
          },
          onError: () => {
            toast.error('Try again!')
          },
        }
      )
    }
   

    onDeleteClose()
  }
  return (
    <>
      <WarningDeleteModal
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        handleProceed={deleteVideo}
        text={scheduled?'video':'draft'}
      />
      <MenuItem
        icon={<Icon as={FaRegTrashCan} color="red.500" h={4} w={4} />}
        opacity={0.75}
        _hover={{ bg: '#ECECEC' }}
        color="red.500"
        onClick={onDeleteOpen}
      >
        Delete{scheduled?' video':' draft'}
      </MenuItem>
    </>
  )
}

export const VideoPageMenu = ({
  eventId,
  creatorId,
  isWatchList,
  myVideo
}: {
  eventId?: string
  creatorId?: string
  isWatchList?: string
  myVideo?: boolean
}) => {
  const {
    isOpen: isReportOpen,
    onOpen: onReportOpen,
    onClose: onReportClose,
  } = useDisclosure()
  const {
    isOpen: isCopyrightOpen,
    onOpen: onCopyrightOpen,
    onClose: onCopyrightClose,
  } = useDisclosure()
  const [value, setValue] = useState('')
  const addWatchlistMutation = useAddWatchlistMutation()
  const deleteWatchlistMutation = useDeleteWatchlistMutation()
  const reportVideoMutation = useReportVideoMutation()
  const { getUser } = useUser()
  const user = getUser()
  const router = useRouter()

  const [watchlistState, setWatchListState] = useState(isWatchList ?? '0')

  useEffect(() => {
    setWatchListState(isWatchList ?? '0')
  }, [isWatchList])

  const addToWatchlist = () => {
    if (user) {
      addWatchlistMutation.mutate(
        {
          eventId,
        },
        {
          onSuccess: () => {
            setWatchListState('1')
            toast.success('Added to watchlist', {
              duration: 5000,
              action: {
                label: 'View Watchlist',
                onClick: () => {
                  router.push('/watchlist')
                },
              },
            })
          },
          onError: () => {
            toast.error('Try again!')
          },
        }
      )
    } else {
      router.push('/auth/login')
    }
  }

  const removeFromWatchlist = () => {
    if (user) {
      deleteWatchlistMutation.mutate(
        {
          eventId,
        },
        {
          onSuccess: () => {
            setWatchListState('0')
            toast.success('Removed from watchlist')
          },
          onError: () => {
            toast.error('Try again!')
          },
        }
      )
    } else {
      router.push('/auth/login')
    }
  }

  const reportVideo = () => {
    // console.log(value)
    reportVideoMutation.mutate(
      {
        eventId,
        creatorId,
        description: value,
      },
      {
        onSuccess: () => {
          toast.success('Reported successfully')
          router.push('/home')
        },
        onError: () => {
          toast.error('Try again!')
        },
      }
    )

    onReportClose()
  }

  return (
    <>
      <ReportVideo
        isOpen={isReportOpen}
        onClose={onReportClose}
        setValue={setValue}
        reportVideo={reportVideo}
      />
      <CopyrightVideo
        isOpen={isCopyrightOpen}
        onClose={onCopyrightClose}
      />
      <MenuItem
        icon={
          watchlistState == '1' ? (
            <BookmarkX color="#ED837F" size={18} />
          ) : (
            <Icons.bookmark color="#3E3E3E" size={15} />
          )
        }
        opacity={0.75}
        _hover={{ bg: '#ECECEC' }}
        onClick={watchlistState == '1' ? removeFromWatchlist : addToWatchlist}
      >
        Watchlist
      </MenuItem>
      <MenuItem
        icon={<Icons.report color="#3E3E3E" size={15} />}
        opacity={0.75}
        _hover={{ bg: '#ECECEC' }}
        onClick={user ? onReportOpen : () => router.push('/auth/login')}
      >
        Report Video
      </MenuItem>
      {
        myVideo?"":  <MenuItem
        icon={<Icons.report color="#3E3E3E" size={15} />}
        opacity={0.75}
        _hover={{ bg: '#ECECEC' }}
        onClick={user ? onCopyrightOpen : () => router.push('/auth/login')}
      >
        Copyright Claim
      </MenuItem>
      }
    
    </>
  )
}
